import AppContext from "context/AppContext";
import { useContext } from "react";
import ProductCard from "./ProductCard";
import ReactSwiper from "components/ui/ReactSwiper";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BestSelling() {
  const { data, trackAction } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className="px-6 md:px-24 py-20 md:py-20 md:pt-32">
      <h1 className="text-4xl font-bold text-center px-6 mb-6">
        {t("related_products")}
      </h1>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-6">
        {/* <div className="absolute top-[calc(50%-2.1rem)] left-[calc(50%-3.8rem)] grid backdrop-blur-sm bg-black/10 rounded-xl p-2 text-2xl font-bold text-center">
          <span className="">Related</span>
          <span className="-mt-1">Products</span>
        </div> */}
        {data?.products
          .filter((x: any) => ["1", "2", "4", "5"].includes(x?.id))
          .map((item: any, i: number) => {
            return (
              <Link
                to={"/" + item?.id}
                onClick={() => trackAction(`Click_related_product_${item?.id}`)}
                key={i}
                className="relative group overflow-hidden"
              >
                <img
                  src={require(`assets/images/products/${item?.images[0]}`)}
                  alt=""
                  className="object-cover rounded-xl shadow-lg border-2 h-[40vw] md:h-[20vw] w-full group-hover:scale-[1.2] duration-300"
                />

                <div className="absolute bottom-10 bg-white/70 px-3">
                  <span className="text-gray-500 line-through">
                    {item?.oldPrice}
                  </span>
                </div>
                <div className="absolute bottom-2 bg-white/70 px-3">
                  <span className="text-lg md:text-xl font-bold mr-2">
                    {item?.price} {t("dh")}
                  </span>
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
}
